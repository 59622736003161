import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/automated-drupal-hash-salt",
  "date": "10th October 2024",
  "title": "Automated Drupal hash salt",
  "summary": "Skpr is simplifying how you configure and manage Drupal's hash salt",
  "author": "Nick Schuch",
  "tag": "Security",
  "tagColor": "yellow",
  "tags": [{
    "name": "announcement"
  }, {
    "name": "drupal"
  }, {
    "name": "security"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Overview`}</h2>
    <p>{`In Drupal, a hash salt is a security feature used to enhance the hashing of one-time login links, cancel links, form tokens, etc.`}</p>
    <p>{`To manage this, development teams have succeeded by integrating with our `}<a parentName="p" {...{
        "href": "/blog/architecting-skpr-configuration-system"
      }}>{`Skpr Configuration System`}</a>{` and
setting these hash salt values on a per-environment basis using our command line interface (CLI).`}</p>
    <p>{`The Skpr platform team saw an opportunity to streamline this solution and is proud to announce that these hash salt values are now automatically
set out-of-the-box for all environments by the platform.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/posts/drupal-hash-salt/hero.png",
        "alt": "Image of a keyboard and set of keys buried in a pile of salt"
      }}></img></p>
    <h2>{`Key benefits`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Easy to setup`}</strong>{` - Integrated into the Skpr Configuration System`}</li>
      <li parentName="ul"><strong parentName="li">{`Less for developers to manage`}</strong>{` - Automatically managed by the platform`}</li>
      <li parentName="ul"><strong parentName="li">{`Enhanced security`}</strong>{` - Stored outside of the document root`}</li>
    </ul>
    <h2>{`How to use Drupal hash salt`}</h2>
    <p>{`Developers can seamlessly integrate our hash salts into their applications using the Skpr configuration system.`}</p>
    <p>{`Below is a snippet demonstrating how easy it is to integrate by updating Drupal's `}<em parentName="p">{`settings.php`}</em>{` configuration.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`$settings['hash_salt'] = $skpr->get('drupal.hash_salt');
`}</code></pre>
    <h2>{`Conclusion`}</h2>
    <p>{`Automated hash salts is another example of Skpr's investment in developer productivity and security.`}</p>
    <p>{`If you have any questions or a security feature you would like to see added to Skpr, `}<a parentName="p" {...{
        "href": "#request-a-demo"
      }}>{`please contact the Skpr team`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      